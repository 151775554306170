<template>
  <div class="container">
    <div class="text-right my-4">
     
    </div>
    
    <div class="text-right"></div>
 <!-- v-data-table  -->

<!-- <d-player :options2="options2"
              @play="play"
              ref="player">
    </d-player> -->

    <v-row>
      <v-col cols="12">
        
          <div class="tableWrapper">
            
            <v-data-table
              :headers="headers"
               :single-select="singleSelect"
              show-select
              item-key="id"
              v-model="selected"
              :items="data"
              :options.sync="options"
              :server-items-length="total"
              :loading="isLoading"
              class="elevation-1"
              hide-default-footer
            >
             <template v-slot:top>
                  <v-container class="grey lighten-5" id="product__container">
                              <v-row no-gutters >
                              <v-col cols="14">
                              <div style="float:right"><v-btn color="primary" small v-show="checkIfOperationExistForModule('delete')" @click.stop = "delete_records()" >Delete</v-btn></div>
                              </v-col>

                                   </v-row>
                          </v-container>
                </template>
              <template v-slot:item.email="{ item }">
                <div class="text-no-wrap">
                  <a style="text-decoration:none" :href="`mailto:${item.email}`" >{{item.email}}</a>
                  </div>
              </template>
              
            </v-data-table>
             <div class="text-center pt-4 pb-4">
              <Pagination
            :isloading="isLoading"
            :startRecord="startRecord"
            :currentPage="currentPage"
            :lastPage="lastPage"
            :lastRecord="lastRecord"
            :totRecords="totRecords"
            :isCurrentPageClass="isCurrentPageClass"
            :perpage="perpage"
            :getLastPageClass="getLastPageClass"
            :totPage="totPage"
            :getPages="getPages"
            @handlePerPage="handlePerPage"
            @paginate="paginate"
            @last="last"
            @getDataByPage="getDataByPage"
            :showPerPage="showPerPage"
             />
            </div>
          </div>
      </v-col>
    </v-row>
   


    
</div>

  
</template>

<script>
import axios from "axios";
import Index from "./Index.vue";
import Buttons from "./component/Buttons.vue";
import Item from "../../components/base/Item.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
import Pagination from "./component/Pagination.vue";
// import videoPlayer from 'vuetify-media-player/src/components/video-player.vue'
// import 'vuetify-media-player/src/style.styl'
// import VueDPlayer from 'vue-dplayer'
// import 'https://unpkg.com/browse/vue-dplayer@0.0.10/dist/vue-dplayer.css'
import MobileDigitalTemp from "./component/MobileDigitalTemp";
export default {
  // components: { Index, Buttons, Loading, Pagination,videoPlayer },
  components: { Index, Buttons, Pagination,MobileDigitalTemp },
  data() {
    return {
      toggleUpdateModal:false,
      //////table data starts here////
      selected: [],
      singleSelect: false,
     
      options: {},
      sort: "",
      total: 0,
      
      
      headers: [
                              
                {
                    text: 'First Name',                
                    value: 'first_name',
                    width:"80px",
                    sortable: false,
                    class: "v-data-table-header ",
                },
                {
                    text: 'Last Name',
                    value: 'first_name',
                    width:"80px",
                    sortable: false,
                    class: "v-data-table-header",
                },
                {
                    text: 'Email',
                    value: 'email',
                    sortable: false,
                    width:"110px",
                    class: "v-data-table-header",
                },
                {
                    text: 'Company',
                    value: 'company',
                    sortable: false,
                    width:"180px",
                    class: "v-data-table-header",
                },
                {
                    text: 'Address',
                    value: 'address',
                    sortable: false,
                    width:"200px",
                    class: "v-data-table-header",
                },
                {
                    text: 'City',
                    value: 'city',
                    sortable: false,
                    width:"100px",
                    class: "v-data-table-header",
                },
                 {
                    text: 'State',
                    value: 'state',
                    sortable: false,
                    width:"100px",
                    class: "v-data-table-header",
                },
                 {
                    text: 'ZIP',
                    value: 'zip',
                    sortable: false,
                    width:"100px",
                    class: "v-data-table-header",
                },
                ,
                 {
                    text: 'Phone',
                    value: 'phone',
                    sortable: false,
                    width:"100px",
                    class: "v-data-table-header",
                },
                ,
                 {
                    text: 'Refered By',
                    value: 'refered_by',
                    sortable: false,
                    
                    class: "v-data-table-header",
                },
                 ,
                 {
                    text: 'Send Date',
                    value: 'inserted_date',
                    sortable: false,
                    width:"100px",
                    class: "v-data-table-header",
                }
                
            ],
     
      ////// table data ends here
      valid: true,
      checkbox: false,
      operation: [],
      modules: [],
      data: [],
      isLoading: true,
      fullPage: true,
      dialog: false,
      dialog1: false,
      dialog2: false,
      deleteItems: [],
      checked_Id: [],
      checkcedstr: "",
      message1: "",
      message2: "",
      upmessage1: "",
      upmessage2: "",
      parent_id: [],
      module_sort: [],
      allSelected: false,
      new: [],
      panel_data: [],
      addModule: {
        moduleName: null,
        parent_id: null,
        module_icon: "mdi-view-dashboard",
        parent_id: null,
        url_slug: null,
        panel_id: null,
      },
      
      checked: false,
      nameRules: [(v) => !!v || "Name is required"],
      urlRules: [(v) => !!v || "Url is required"],
      addModulelabel: null,
      //pagination code
      perpage: 10,
      totRecords: null,
      page: 1,
      isLoading: true,
      fullPage: true,
      query: null,
      showPerPage: false,
      ///
    };
  },
  computed: {
    //pagination computed methods
    startRecord: function () {
      return parseInt(this.lastRecord) - parseInt(this.perpage) + parseInt(1);
    },
    lastRecord: function () {
      return this.perpage * this.currentPage;
    },
    isCurrentPageClass: function () {
      return this.currentPage === 1 ? "" : "notactivespan";
    },
    getLastPageClass: function () {
      return parseInt(this.lastPage) === parseInt(this.currentPage)
        ? ""
        : "notactivespan";
    },
    totPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    getPages: function () {
      return this.$utils.getPages(
        this.lastPage,
        this.currentPage,
        this.totPage
      );
    },
    currentPage: function () {
      return this.page;
    },
    lastPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    ///////
  },
  methods: {
    checkIfOperationExistForModule(type){
        return this.$utils.checkIfOperationExistForModule(this, type)
    },
    delete_records(){
      var view = this
      if(view.selected.length > 0){
        view.$swal
                .fire({
                title: "Are you sure ?",
                text:
                "This will remove record.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes, remove it !",
                cancelButtonText: "No, cancel !",
                closeOnConfirm: false,
                closeOnCancel: false,
                }).then((result) => {
                if (result.isConfirmed) {
                   let secid = []
                        for(var l in view.selected){
                                      secid.push(this.selected[l].id)
                        }
                    const path = view.$url("PRODUCER_MAIL")+"?id="+secid.join();;
                    this.isLoading = true
                    this.$fetch({ requiresAuth: true, operation : 'view', vueScope: view  })
                      .delete(path)
                      .then((res) => {
                        this.isLoading = false;
                        view.selected = []
                        view.getData(1)
                        this.$swal.fire({
                                          icon: "success",
                                          title: "Producer Mail",
                                          text: "Record has been deleted successfully",
                                      });
                          
                  
                      })
                      .catch((error) => {
                        console.error(error);
                      });
                } else {
                    view.selected = []
                   
                }
                });
        

      }else{

         view.$swal.fire({
                    position: "bottom-end",
                    icon: "error",
                    title: "Please select Record",
                  });
      }
      


    },
    getDataByPage(value) {
      console.log(value);
      this.page = value;
      this.getData(this.page);
    },
    handlePerPage(value) {
      console.log(value);
      this.perpage = value;
      this.getData(this.page);
    },
    open_img_popup(imgurl){
            var view = this            
            window.open(imgurl, "", "toolbar=yes,scrollbars=yes,resizable=yes,top=100,,width=600,height=600");
        },
    paginate(n) {
      console.log(n);
      this.page = n === 0 ? 1 : parseInt(this.currentPage) + parseInt(n);
      this.getData(this.page);
    },
    last(value) {
      console.log(value);
      this.page = this.lastPage;
      this.getData(this.page);
    },
    
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD");
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    
    getData(page = "", query = "") {
      let check = this;
      const path = check.$url("PRODUCER_MAIL")+"?page="+page;
      check.isLoading = true;
      console.log(path + "checking path")
      this.$fetch({ requiresAuth: true, operation : 'view', vueScope: check  })
        .get(path)
        .then((res) => {
          check.isLoading = false;
          console.log(res);
          let payload   = res.data.payload.data       
          this.total    =  payload.length;
          this.totRecords = res.data.payload.totaldata;
          this.data = payload
          // this.pageNo = res.data.lastPage;
    
        })
        .catch((error) => {
          console.error(error);
        });
    },
    
      
    

  },
  mounted() {
     this.getData(1);
  },
  watch: {

 '$store.state.Rawdata.current_active_module': function(o) {
   
 if(o){
 this.getData(1);
 }
 },
//  page: function (ob) {
//  this.getData(ob);
//  },
 },





};
</script>
